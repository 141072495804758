<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           Listado de empresas agrupadas por recorrido<br><br>
                              <router-link :to="{ name: 'Produccion' }">
                              <button type="button" class="btn btn-outline-success mb-1 mb-md-0">Volver atras</button>
                            </router-link>
                          </p>
                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive" >
                  
                  <table width="100%">
                    <thead > 
                    <tr>
                      <th style="color: #fff;background-color: #212529;border-color: #32383e;padding:5px;"> GRUPO 1</th>
                      <th style="color: #fff;background-color: #212529;border-color: #32383e;padding:5px;"> GRUPO 2</th>
                    </tr>
                  </thead>

                    <tr>
                      <td valign="top">
                        
                        <table id="grupo1" class="table" style="border:5px solid #bdafaf;">
                            <thead>
                              <tr style="color: #fff;background-color: rgb(237, 239, 241);border-color: bdafaf;">
                                <th>Id</th>
                                <th>Empresa</th>
                                <th>Pedido</th>
                                
                              </tr>
                            </thead>
                            <tbody>

                              <tr v-for="grupoa in grupoa"  v-bind:key="grupoa.id">
                                <td>{{grupoa.id_consumos}}</td>
                                <td>{{grupoa.name}}</td>
                                <td :id="'empresa' + grupoa.catering_id">0</td>
                              </tr>

                            </tbody>
                          </table>



                      </td>
                      <td valign="top">
                        
                        <table id="grupo2" class="table" style="border:5px solid #bdafaf;">
                            <thead>
                               <tr style="color: #fff;background-color: rgb(237, 239, 241);border-color: bdafaf;">
                                <th>Id</th>
                                <th>Empresa</th>
                                <th>Pedido</th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="grupob in grupob"  v-bind:key="grupob.id">
                                
                                <td>{{grupob.id_consumos}}</td>
                                <td>{{grupob.name}}</td>
                                <td :id="'empresa' + grupob.catering_id">0</td>
                              
                              </tr>

                            </tbody>
                          </table>


                      </td>
                    </tr>

                  </table>

                  



                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'grupos',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Grupos de empresas',
      descripcion:'Portal de Grupos',
      grupoa:[],
      grupob:[]
   }), 
    methods:{


                
                 getData:function(){

                  let id = this.$route.params.id;
                  console.log(id)
                   

                      axios
                          .get('https://matrix.foodservice.com.ar/empresas/?empresas_grupo=1').then((response)=>{
                          
                           this.grupoa = response.data;
                            
                          

                        }).then(()=>{
                          console.log("datos cargados")
                          });


                        axios
                      .get('https://matrix.foodservice.com.ar/empresas/?empresas_grupo=2').then((response)=>{
                          
                           this.grupob = response.data;

                        }).then(()=>{
                          console.log("datos cargados")
                          });

                   

                    axios
                      .get('https://matrix.foodservice.com.ar/helados-pedidos?produccion_id='+id+'&_limit=1000')
                      .then(response => {
                        
                        setTimeout(function(){

                            var count = 0;
                            for (var i = 0; i < response.data.length; i++) {
                              count+=response.data[i].sabor_order;
                              $("#empresa"+response.data[i].id_empresa).html(count);
                              console.log(count)
                              
                            }

                               

                        },2000);
                       
                       

                      })
                      .catch(error => {
                      
                         console.log(error);
                        

                      });





                  },
                  

                },
                
                mounted(){
                    
                    this.getData();
                   
                },

};



</script>

