<template>
			
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          PRODUCCION
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">

          <li class="nav-item">
            <router-link :to="{ name: 'Produccion' }" class="nav-link">
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Helados</span>
            </router-link>
          </li>

           
        
        </ul>
      </div>
    </nav>
 
</template>

<script>


export default {
  name: 'Menu'
};


</script>