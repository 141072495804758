<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">{{descripcion}}</p>
                        </div>  
                      </div>
                    </div>
                  </div>    


            </div>
     

</template>

<script>


export default {
  name: 'Logistica',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Logistica',
      descripcion:'Portal de Logistica',
   }),

};

</script>