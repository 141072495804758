<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                             {{descripcion}} <br><br>
                              <router-link :to="{ name: 'Produccion' }">
                              <button type="button" class="btn btn-outline-success mb-1 mb-md-0">Volver atras</button>
                            </router-link>
                          </p>
                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  

                  <table id="dataTableExample" class="table">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Sabores</th>
                        <th>Cantidad</th>
                        <th>Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                     <tr v-for="ordenes in ordenes"  v-bind:key="ordenes.id">
                         <td>{{ordenes.id}}</td>
                         <td>{{ordenes.helados_sabores.name}}</td>
                         <td>{{ordenes.litros}}</td>
                         <td>{{ordenes.stock}}</td>
                        
                      </tr>

                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


import axios from 'axios';

export default {
  name: 'Orders',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Stock',
      descripcion:'Portal de Producción y stock',
      ordenes:[]
   }), 
   mounted:function(){

      let id = this.$route.params.id;
      console.log(id)
                
               
                      axios
                      .get('https://helados-fs.firebaseio.com/produccion.json?orderBy=%22helados_fecha/id%22&equalTo='+id+'&print=pretty').then((response)=>{
                          
                           this.ordenes = response.data;
                           console.log(this.ordenes)

                        }).then(()=>{
                          console.log("datos cargados")
                          });
              

             
 },


                
               
};



</script>