<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                            <router-link :to="{ name: 'Nueva' }" class="nav-link">
                              <button type="button" class="btn btn-outline-success mb-1 mb-md-0">Crear nueva producción</button>
                            </router-link>
                          </p>
                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  
                  

                  <table id="produccion" class="table">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Fecha Desde</th>
                        <th>Fecha Hasta</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="fecha in fecha"  v-bind:key="fecha.id">
                         <td>{{fecha.id}}</td>
                        <td>{{fecha.desde}}</td>
                        <td>{{fecha.hasta}}</td>
                        
                        <td>


                          <button type="button" v-on:click="stock(fecha.id)" class="btn btn-outline-success mb-1 mb-md-0">Stock</button>

                           <button style="margin-left:5px;" v-on:click="ordenes(fecha.id)" type="button"  class="btn btn-outline-success mb-1 mb-md-0">Ordenes</button>

                          <!-- <button style="margin-left:5px;" v-on:click="grupos(fecha.id)" type="button" class="btn btn-outline-success mb-1 mb-md-0">Grupos</button>-->

                        </td>
                      </tr>

                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


import axios from 'axios';


export default {
  name: 'Produccion',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Produccion',
      descripcion:'Portal de Produccion',
      fecha:[]
   }), 
    methods:{
                
                 getData:function(){
                      axios
                      .get('https://helados-fs.firebaseio.com/fechas.json?print=pretty').then((response)=>{
                            console.log('hola')
                           this.fecha = response.data;

                        }).then(()=>{
                         console.log("datos cargados")
                          });
                  },
                  stock:function(id){
                    
                    this.$router.push({ path: '/orders/'+id })
                 },

                  ordenes:function(id){
                    
                    this.$router.push({ path: '/ordersempresas/'+id })
                 },
                 grupos:function(id){
                    
                    this.$router.push({ path: '/grupos/'+id })
                 },

                },
                
                mounted(){
                    this.getData();
                    console.log('lossc')
            

                   
                }

};



</script>