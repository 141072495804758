<template>
    <div id="login">
        <div class="main-wrapper">
        <div class="page-wrapper full-page">
            <div class="page-content d-flex align-items-center justify-content-center">

                <div class="row w-100 mx-0 auth-page">
                    <div class="col-md-8 col-xl-6 mx-auto">
                        <div class="card">
                            <div class="row">
                <div class="col-md-4 pr-md-0">
                  <div class="auth-left-wrapper">

                  </div>
                </div>
                <div class="col-md-8 pl-md-0">
                  <div class="auth-form-wrapper px-4 py-5">
                    <a href="login.html#" class="noble-ui-logo d-block mb-2">FS <span>PRODUCCIÓN</span></a>
                    <h5 class="text-muted font-weight-normal mb-4">Accede a tu portal Food Service todos los dias!.</h5>
                    <form class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputEmail1">Número de Documento</label>
                        <input type="email" id="username" name="username" v-model="input.username" class="form-control"  placeholder="55555555" required>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputPassword1">Contraseña</label>
                        <input type="password" id="password" name="password" v-model="input.password" class="form-control" autocomplete="current-password" placeholder="Password">
                      </div>
                    
                      <div class="mt-3">
                        <a href="#" class="btn btn-primary mr-2 mb-2 mb-md-0 text-white"  v-on:click="login()">Acceder</a>
                      </div>
                     
                    </form>
                  </div>
                </div>
              </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    </div>
</template>




<script>
    
  /* nuevo login */

import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";
let plataforma = "Helados";

export default {
  name: "Login",
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    isLector(modulo) {
      if (
        JSON.parse(sessionStorage.getItem("userPermisos")).find(
          (record) => record.ModulosAccess === modulo
        )
      ) {
        var status = JSON.parse(sessionStorage.getItem("userPermisos")).find(
          (record) => record.ModulosAccess === modulo
        ).Lector;
        console.log(modulo + " : " + status);
        return status;
      } else {
        console.log(modulo + " : " + false);
        return false;
      }
    },
    olvide() {
      (async function() {
        await Swal.fire({
          title: "Olvidaste tu contraseña?",
          html:
            '<form action="#" id="searchForm">' +
            '<span style="color:red;font-size:14px;display:none;" id="errordni">DNI no coincide con nuestros registros</span><input required type="text"  id="dni" class="swal2-input" placeholder="TU DNI">' +
            '<span style="color:red;font-size:14px;display:none;" id="errormail">Email no coincide con tu DNI</span><input  required type="email" id="correo" class="swal2-input" placeholder="Ingresa tu email de recuperación">' +
            '<button type="button" id="enviar" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;">Enviar</button>' +
            "</form>",
          showCancelButton: false,
          showConfirmButton: false,
          didOpen() {
            $("#enviar").click(function() {
              if ($("#dni").val() == "") {
                console.log("vacio");
              } else if ($("#correo").val() == "") {
                console.log("vacio");
              } else {
                axios
                  .get(
                    "https://auth.foodservice.com.ar/?type=user&dni="+$("#dni").val()+"&access_token=1234567"
                  )
                  .then((response) => {
                    console.log(response.data[0]);
                    var uId = response.data[0].id;

                    if (response.data[0].Email_de_recupero[0].correo != null) {
                      if (
                        $("#correo").val() ==
                        response.data[0].Email_de_recupero[0].correo
                      ) {
                        $("#searchForm").hide();
                        Swal.showLoading();

                        var naci = response.data[0].datos.leg_fecnac.split("-");
                        var pass = naci[2] + naci[1] + naci[0];
                        var mensaje = "";

                        mensaje +=
                          "Hola " +
                          response.data[0].nombre +
                          " " +
                          response.data[0].apellido +
                          ". !Ya reestablecimos tu contraseña de acceso a "+plataforma+". Ingresa nuevamente a https://produccion.foodservice.com.ar  e ingresa tu clave temporal que es tu fecha de nacimiento: " +
                          pass +
                          ". Recuerda cambiar tu clave y recordarla en un futuro,  Muchas gracias";

                        axios
                          .post(
                            " https://consumos.foodservice.com.ar/api/enviomail",
                            {
                              from: "genesis@foodservice.com.ar",
                              to: response.data[0].Email_de_recupero[0].correo,
                              subject: "Reestablece tu contraseña!",
                              body: mensaje,
                            }
                          )
                          .then((response) => {
                            if (response.statusText == "OK") {
                            


                        firebase.database().ref('password/' + uId +'').update({
                          contrasena:null

                        })
                        .then(()=>{
                          Swal.close();
                                    Swal.fire(
                                      "Revisa tu casilla de correo!",
                                      "Si no lo encuentras en tu bandeja de entrada, verifica en tu casilla de spam",
                                      "success"
                                    );
                        })
                        .catch(e => {
                          console.log(e);
                        });


                             
                            }
                          });
                      } else {
                        console.log("correo incorrecto");
                        $("#errormail").slideDown();
                      }
                    } else {
                      $("#errormail").slideDown();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    $("#errordni").slideDown();
                    $("#errormail").hide();
                  });
              }
            });
          },
        });
      })();
    },

    login() {
      if (this.input.username != "" && this.input.password != "") {
        let user = this.input.username;
        let pass = this.input.password;

        console.log(user);
        console.log(pass);

        axios
          .get("https://auth.foodservice.com.ar/?type=login&user="+user+"&access_token=1234567")
          .then((response) => {
            // Handle success. response.data[0].datos

            this.$emit("authenticated", true);

            console.log(response.data[0].datos);

            if (response.data[0].datos != null) {
              var nacimiento = response.data[0].datos.leg_fecnac.split("-");
              var password = nacimiento[2] + nacimiento[1] + nacimiento[0];

              
              if (response.data[0].Password[0].contrasena == null) {
                console.log(pass + " == " + password);

                if (pass == password) {
                  sessionStorage.setItem(
                    "userPermisos",
                    JSON.stringify(response.data[0].Permisos)
                  );
                  sessionStorage.setItem(
                    "userData",
                    JSON.stringify(response.data[0].datos)
                  );
                  sessionStorage.setItem(
                    "userId",
                    response.data[0].id
                  );

                  if (this.isLector(plataforma) == true) {
                    location.href = "/";
                  } else {
                    Swal.fire(
                      "Acceso denegado",
                      "No tienes los permisos necesarios para acceder a Genesis",
                      "error"
                    );
                    sessionStorage.clear();
                  }
                } else {
                  Swal.fire(
                    "Datos incorrectos",
                    "Verifique sus datos de acceso",
                    "error"
                  );
                }
              } else {
                password = response.data[0].Password[0].contrasena;


            
                if (pass == password) {
                  sessionStorage.setItem(
                    "userPermisos",
                    JSON.stringify(response.data[0].Permisos)
                  );
                  sessionStorage.setItem(
                    "userData",
                    JSON.stringify(response.data[0].datos)
                  );
                  sessionStorage.setItem(
                    "userId",
                    JSON.stringify(response.data[0].id)
                  );

                  if (this.isLector(plataforma) == true) {
                    location.href = "/";
                  } else {
                    Swal.fire(
                      "Acceso denegado",
                      "No tienes los permisos necesarios para acceder a Genesis",
                      "error"
                    );
                    //sessionStorage.clear();
                  }
                } else {
                  Swal.fire(
                    "Datos incorrectos",
                    "Verifique sus datos de acceso",
                    "error"
                  );
                }
              }
            } else {
              Swal.fire(
                "Datos incorrectos",
                "Verifique sus datos de acceso",
                "error"
              );
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire(
              "Datos incorrectos",
              "Verifique sus datos de acceso",
              "error"
            );
            this.input.username = "";
            this.input.password = "";
          });
      } else {
        Swal.fire("Campos vacios", "Verifique sus datos de acceso", "warning");
      }
    },
  },
};

/* nuevo login */
</script>

