import { createWebHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Coordinacion from "@/views/Coordinacion.vue";
import Produccion from "@/views/Produccion.vue";
import Orders from "@/views/Orders.vue";
import OrdersEmpresas from "@/views/OrdersEmpresas.vue";
import Logistica from "@/views/Logistica.vue";
import Zonal from "@/views/Zonal.vue";
import Nueva from "@/views/Nueva.vue";
import Login from "@/views/Login.vue";
import Grupos from "@/views/Grupos.vue";

const routes = [

 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/Coordinacion",
    name: "Coordinacion",
    component: Coordinacion,
  },
   {
    path: "/Produccion",
    name: "Produccion",
    component: Produccion,
  },
   {
    path: "/orders/",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/orders/:id",
    name: "Orders",
    component: Orders,
  },
 {
    path: "/ordersempresas/:id",
    name: "OrdersEmpresas",
    component: OrdersEmpresas,
  },

   {
    path: "/Logistica",
    name: "Logistica",
    component: Logistica,
  },

  {
    path: "/Zonal",
    name: "Zonal",
    component: Zonal,
  },
  {
    path: "/Nueva",
    name: "Nueva",
    component: Nueva,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },{
    path: "/grupos/:id",
    name: "Grupos",
    component: Grupos,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;