<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                            
                              <router-link :to="{ name: 'Produccion' }">
                              <button type="button" class="btn btn-outline-success mb-1 mb-md-0">Volver atras</button>
                            </router-link>
                          </p>
                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  

                  <table id="empresa_orders" class="table" >
                    <thead>
                    <tr>
                         <td>ZONAL</td>
                         <td>OPERACIÓN</td>
                         <td v-for="sabores in sabores"  v-bind:key="sabores.id">{{sabores.name}}</td>
                         
                    </tr>
                    </thead>
                    <tbody>

                      <tr v-for="pedidos in pedidos"  v-bind:key="pedidos">
                        <td>{{pedidos.zonal_name}}</td>
                        <td>{{pedidos.name_empresa}}</td>
                        <td v-for="sabor in sabor"  v-bind:key="sabor" :class="sabor.sabor + pedidos.id_empresa">0</td>
                        
                       
                        
                      </tr>
                

                    </tbody>
                  </table>



                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


import axios from 'axios';
import $ from 'jquery';
require( 'datatables.net' );
require( 'datatables.net-buttons/js/buttons.colVis.js' )();
require( 'datatables.net-buttons/js/buttons.html5.js' )();  
require( 'datatables.net-buttons/js/buttons.flash.js' )();  
require( 'datatables.net-buttons/js/buttons.print.js' )();  


export default {
  name: 'Orders',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Ordenes por empresas',
      descripcion:'Portal de Ordenes',
      sabores:[],
      pedidos:[],
      sabor:[]
   
   }), 

   mounted:function(){

    let id = this.$route.params.id;
    let sabor = [];


                  /* --- MOSTRAR REGISTROS ---*/
              
                    axios
                      .get('https://helados-fs.firebaseio.com/sabores.json?print=pretty')
                      .then(response => {
                        
                   
                        this.sabores = response.data;

                        for (var i = 0; i < response.data.length; i++) {
                          sabor.push({'sabor':response.data[i].name.toLowerCase().replace(/ /g, "")})
                        }

                        this.sabor = sabor;
                       

                      })
                      .catch(error => {
                      
                         console.log(error);
                        

                      });

                  
                    
                      /* --- MOSTRAR REGISTROS ---*/
              
                    axios
                      .get('https://helados-fs.firebaseio.com/pedidos.json?orderBy=%22produccion_id%22&equalTo="'+id+'"&print=pretty')
                      .then(response => {
                        
                    

                          var pedidos = []

                          $.each(response.data,function(clave,item){

                              pedidos.push(item);

                               

                          });
                         
                          this.pedidos = pedidos;
                          
                       
                            var datos = pedidos.filter((data, index, j) => 
                              index === j.findIndex((t) => (t.id_empresa === data.id_empresa))
                            )

                             this.pedidos = datos;

                       
                      
                            
                        setTimeout(function(){

         

                            $.each(response.data,function(k,v){

                              var gusto = v.sabor_name.toLowerCase().replace(/ /g, "");
                              var empresa = v.id_empresa;
                              $("."+gusto+empresa).html(v.sabor_order)
                               
                            
                            })
                               
                             $('#empresa_orders').DataTable( {
                                  "scrollX": true,
                                 dom: 'Bfrtip',
                
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]
                              });
                               

                        },1000);
                       
                       

                      })
                      .catch(error => {
                      
                         console.log(error);
                        

                      });

                  /* --- CIERRA MOSTRAR REGISTROS ---*/  




             
 },

                
               
};


</script>