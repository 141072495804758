<template>

    <!-- partial:../../partials/_navbar.html -->
      <nav class="navbar">
        <a href="blank-page.html#" class="sidebar-toggler">
          <i data-feather="menu"></i>
        </a>
        <div class="navbar-content">
       
          <ul class="navbar-nav">
           
           <li class="nav-item dropdown nav-profile">
              <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img :src="profilepic" alt="profile">
              </a>
              <div class="dropdown-menu" aria-labelledby="profileDropdown">
                <div class="dropdown-header d-flex flex-column align-items-center">
                  <div class="figure mb-3">
                    <img :src="profilepic" alt="profile">
                  </div>
                  <div class="info text-center">
                    <p class="name font-weight-bold mb-0">{{name}} {{lastname}}</p>
                    <p class="email text-muted mb-3">{{email}}</p>
                  </div>
                </div>
                <div class="dropdown-body">
                  <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item">
                      <a href="javascript:;" v-on:click="logout()" class="nav-link">
                        <i data-feather="log-out"></i>
                        <span>Log Out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <!-- partial -->

</template>

<script>


const userData = JSON.parse(sessionStorage.getItem('userData'));





export default {
    name: 'Navbar',
    data: () => ({
      
     name: userData.leg_nombre,
     lastname:userData.leg_apellido,
     email:userData.Leg_mail,
     profilepic:"./assets/images/default.png",

    }),
    methods: {
      
      logout() {
        sessionStorage.removeItem('userData')
        
        location.href="/";
      }
    },
  };
</script>